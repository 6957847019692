import axios from 'axios';

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key] && values[key] !== 0) {
			errors[key] = "O campo " + value + " é obrigatório."
		}
	}

	return errors;
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};

export const formatHourInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");

	input.target.value = value;

	return input;
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatDataInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCepInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance.get(`https://viacep.com.br/ws/${cep}/json/`, {}).then(res => {
			setFieldValue(nomeEndereco, res.data.logradouro);
			setFieldValue(nomeBairro, res.data.bairro);
			setFieldValue(nomeCidade, res.data.localidade);
			setFieldValue(nomeUf, res.data.uf);
		}).catch(err => {
		});
	}

	return formatCepInput(input);
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	// eslint-disable-next-line
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.trim();

	return valor
};

export const listaUfs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO'
];

export const listaSimNao = [
	{ value: 1, label: 'Sim' },
	{ value: 0, label: 'Não' }
];

export const listaStatus = [
	{ value: 1, label: 'Ativo' },
	{ value: 0, label: 'Inativo' }
];

export const listaNivel = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
];

export const displayValueCurrency = (value) => {
	return value ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const displayValueFixed = (value, casas) => {
	return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
	return value ? `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const formatNumber = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const formatPercentInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, ''));

	if (Math.ceil(input.target.value) === 0) {
		input.target.value = '';
		return input.target.value;
	}

	if (input.target.value > 100) return 100 + '%';

	input.target.value = input.target.value + '%';
	input.target.setSelectionRange(input.target.value.length - 1, input.target.value.length - 1);

	return input.target.value;
};

export const pluralMes = (valor) => {
	return valor <= 1 && valor >= -1 ? `${valor} mês` : `${valor} meses`;
};

export const pluralAno = (valor) => {
	return valor <= 1 && valor >= -1 ? `${valor} ano` : `${valor} anos`;
};

// eslint-disable-next-line
export const regexCPF = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2})$/;

export const validaCpf = (cpf) => {
	var add = 0;
	var rev = 0;

	cpf = cpf.replace(/[^\d]+/g, '');

	if (cpf === '') return false;

	if (cpf.length !== 11) return false;

	for (let i = 0; i < 9; i++) {
		add += parseInt(cpf.charAt(i)) * (10 - i);
	}

	rev = 11 - (add % 11);

	if (rev === 10 || rev === 11) rev = 0;

	if (rev !== parseInt(cpf.charAt(9))) return false;

	add = 0;

	for (let i = 0; i < 10; i++) {
		add += parseInt(cpf.charAt(i)) * (11 - i);
	}

	rev = 11 - (add % 11);

	if (rev === 10 || rev === 11) rev = 0;

	if (rev !== parseInt(cpf.charAt(10))) return false;

	return true;
}