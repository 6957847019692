import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const CriancaPage = lazy(() =>
	import("./crianca/CriancaPage")
);

const ProfissionalPage = lazy(() =>
	import("./profissional/ProfissionalPage")
);

const DominioPage = lazy(() =>
	import("./dominio/DominioPage")
);

const CompetenciaPage = lazy(() =>
	import("./competencia/CompetenciaPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const FamiliarPage = lazy(() =>
	import("./familiar/FamiliarPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/crianca" component={CriancaPage} />
				<Route path="/profissional" component={ProfissionalPage} />
				<Route path="/dominio" component={DominioPage} />
				<Route path="/competencia" component={CompetenciaPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/familiar" component={FamiliarPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
