export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Painel",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Crianças",
				root: true,
				icon: "fas fa-users",
				page: "crianca",
				bullet: "dot"
			},
			{
				title: "Profissionais",
				root: true,
				icon: "fas fa-users",
				page: "profissional",
				bullet: "dot"
			},
			{
				title: "Familiares",
				root: true,
				icon: "fas fa-users",
				page: "familiar",
				bullet: "dot"
			},
			{
				title: "Competências",
				root: true,
				icon: "fas fa-list",
				page: "competencia",
				bullet: "dot"
			},
			{
				title: "Domínios",
				root: true,
				icon: "fas fa-list",
				page: "dominio",
				bullet: "dot"
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Relatório financeiro",
						bullet: "dot",
						page: "relatorio/financeiro"
					},
					{
						title: "Relatório avaliações",
						bullet: "dot",
						page: "relatorio/avaliacao"
					},
				]
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
